.footer {
    display: flex;
    padding: 2%;
}
.trademark{
    text-align: center;
    padding: 0.5rem;
}
.footer > div {
    width: 33.33%;
    text-align: center;
}
.footer a {
    color : inherit;
    text-decoration: none;
}